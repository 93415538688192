import DatePicker from "../../components/DatePicker/DatePicker.js";
import Page from "../../components/page/Page.js";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CloseIcon from "../../icons/CloseIcon.js";
import { LargeSpacer, SmallSpacer } from "../../components/Spacer/Spacer.js";
import { formatDate, adjustDateForTimeZone } from "../../tools/formatDate.js";
import { getProjectInfo, addProject, deleteProject } from "./ProjectManager.js";
import { TextField, TextEditor } from "../../components/TextEntry/TextEntry.js";
import Button from "../../components/Button/Button.js";
import localURL from "../../localURL.json";

function ProjectEditor() {
    const params = useParams();
    const id = params.id;
    const now = formatDate(new Date());
    var isNewProject = null;

    const [errorText, setErrorText] = useState(null);

    const [project, setProject] = useState({
        endDate: new Date(),
    });

    const [images, setImages] = useState(new Array());

    const [title, setTitle] = useState("New Project");

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    useEffect(() => {
        if (id) {
            getProjectInfo(id).then((info) => {
                setProject({
                    ...info,
                    startDate: info.startDate ? new Date(info.startDate) : null,
                    endDate: info.endDate ? new Date(info.endDate) : null,
                });

                if (info) {
                    setTitle("Edit Project");
                }
            });
        }
    }, []);

    async function submit() {
        console.log(project.images, images);
        if (project.title && project.info) {
            if (!isNewProject && project.id) {
                await deleteProject(project.id);
            }

            await addProject(project, images);
            navigateTo("/manageProjects");
        } else {
            setErrorText(
                "Please fill out a title, description, and select an end date before submitting."
            );
        }
    }

    return (
        <Page title={title} authScope="admin">
            <>
                <p>Title:</p>
                <TextField
                    type="text"
                    value={project?.title ?? ""}
                    onChange={(event) =>
                        setProject({
                            ...project,
                            title: event.target.value,
                        })
                    }
                />

                <SmallSpacer />

                <p>Description:</p>
                <TextEditor
                    name="Description"
                    value={project?.info ?? ""}
                    onChange={(event) =>
                        setProject({
                            ...project,
                            info: event.target.value,
                        })
                    }
                />

                <SmallSpacer />

                <h3>Images</h3>

                <input
                    type="file"
                    multiple="multiple"
                    accept="image/*"
                    onChange={(event) => {
                        if (event.target.files) {
                            setImages((images) => [
                                ...images,
                                ...event.target.files,
                            ]);
                        }
                    }}
                />

                <SmallSpacer />

                <div style={{ display: "block" }}>
                    {Array.from(project.images ?? []).map((image) => (
                        <div
                            style={{
                                position: "relative",
                                width: "fit-content",
                                display: "inline-block",
                                overflowX: "auto",
                            }}
                        >
                            <img
                                src={localURL.api + "/assets/" + image}
                                style={{
                                    maxHeight: "200px",
                                    marginRight: "6px",
                                    zIndex: "1",
                                    borderRadius: "8px",
                                }}
                            />

                            <button
                                onClick={() => {
                                    setProject({
                                        ...project,
                                        images: project.images.filter(
                                            (i) => i !== image
                                        ),
                                    });
                                }}
                                className="backgroundBlur"
                                style={{
                                    zIndex: "100",
                                    position: "absolute",
                                    top: "6px",
                                    right: "12px",
                                    border: "none",
                                    height: "32px",
                                    width: "32px",
                                    borderRadius: "16px",
                                }}
                            >
                                <CloseIcon size="12px" />
                            </button>
                        </div>
                    ))}
                </div>

                <div style={{ display: "block" }}>
                    {Array.from(images).map((image) => (
                        <div
                            style={{
                                position: "relative",
                                width: "fit-content",
                                display: "inline-block",
                                overflowX: "auto",
                            }}
                        >
                            <img
                                src={URL.createObjectURL(image)}
                                style={{
                                    maxHeight: "200px",
                                    marginRight: "6px",
                                    zIndex: "1",
                                    borderRadius: "8px",
                                }}
                            />

                            <button
                                onClick={() => {
                                    setImages((images) =>
                                        images.filter((i) => i !== image)
                                    );
                                }}
                                className="backgroundBlur"
                                style={{
                                    zIndex: "100",
                                    position: "absolute",
                                    top: "6px",
                                    right: "12px",
                                    border: "none",
                                    height: "32px",
                                    width: "32px",
                                    borderRadius: "16px",
                                }}
                            >
                                <CloseIcon size="12px" />
                            </button>
                        </div>
                    ))}
                </div>

                <SmallSpacer />

                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            display: "inline-block",
                            width: "fit-content",
                            paddingRight: "40px",
                            position: "relative",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <p style={{ display: "inline-block" }}>
                                Start Date
                            </p>

                            <button
                                onClick={() =>
                                    setProject({ ...project, startDate: null })
                                }
                                style={{
                                    display: "inline-block",
                                    float: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginLeft: "auto",
                                    border: "none",
                                    background: "none",
                                }}
                            >
                                <CloseIcon
                                    fill="var(--textColor3)"
                                    size="12px"
                                />
                            </button>
                        </div>

                        <DatePicker
                            name="Start Date"
                            value={formatDate(
                                project?.startDate ?? now,
                                "YYYY-MM-DD"
                            )}
                            minValue="2001-08-06"
                            maxValue={now}
                            onChange={(event) => {
                                var newDate = adjustDateForTimeZone(
                                    new Date(event.target.value)
                                );
                                const endDate = project.endDate;
                                if (endDate && newDate > endDate) {
                                    newDate = endDate;
                                }

                                setProject({
                                    ...project,
                                    startDate: newDate,
                                });
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "inline-block",
                            width: "fit-content",
                            paddingRight: "40px",
                        }}
                    >
                        <p>End Date</p>
                        <DatePicker
                            name="End Date"
                            value={formatDate(project?.endDate, "YYYY-MM-DD")}
                            minValue="2001-08-06"
                            maxValue={now}
                            onChange={(event) =>
                                setProject({
                                    ...project,
                                    endDate: adjustDateForTimeZone(
                                        new Date(event.target.value)
                                    ),
                                })
                            }
                        />
                    </div>
                </div>

                <LargeSpacer />

                <div>
                    <Button
                        action={async () => {
                            await submit();
                        }}
                        title="Submit"
                        color="white"
                        backgroundColor="var(--green)"
                        margin="0"
                    />

                    <Button
                        action={async () => {
                            navigateTo("/manageProjects");
                        }}
                        title="Cancel"
                        color="Red"
                        margin="12px"
                    />
                </div>

                {errorText && <p>{errorText}</p>}
            </>
        </Page>
    );
}

export default ProjectEditor;
