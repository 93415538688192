import { Link } from "react-router-dom";
import UserManager from "../../UserManager.js";
import { useEffect, useState } from "react";
import PageHeader from "./PageHeader.js";
import './PageStyles.css';
import { Spacer, SmallSpacer, LargeSpacer } from "../../components/Spacer/Spacer.js";
import Button from "../Button/Button.js";

function Page(props) {
    var [isLoaded, setIsLoaded] = useState(false);
    var [canAccess, setCanAccess] = useState(false);

    useEffect(() => {
        if (!props.authScope) { 
            setCanAccess(true); 
            setIsLoaded(true);
        } else {
            UserManager.getStoredUser().then((user) => {
                setCanAccess(user?.scopes?.includes(props.authScope));
                setIsLoaded(true);
            });
        }
    }, [props.authScope]);

    return (
        <div
            style={{
                background: props.background ?? "var(--backgroundColor)",
                padding: 12,
                maxWidth: 1200,
                marginLeft: "auto",
                marginRight: "auto"
            }}
        >
            {isLoaded && (
                <>
                    {canAccess ? (
                        <>
                            {props.title && (
                                <PageHeader>{props.title}</PageHeader>
                            )}
                            {props.children}
                        </>
                    ) : (
                        <>
                            <Spacer height="100px" />

                            <p>
                                You need to be logged in with the proper
                                permissions to view this page.
                            </p>

                            <SmallSpacer />

                            <Button
                                style={{
                                    float: "left",
                                }}
                                to="/login"
                                title="Log In"
                                color="var(--cyan)"
                            />
                        </>
                    )}
                    
                    <LargeSpacer/>

                    <p>
                        <small>
                            &copy; Mark Reggiardo 2024. All rights reserved.
                        </small>
                    </p>
                    
                    <SmallSpacer/>
                </>
            )}
        </div>
    );
}

export default Page;
