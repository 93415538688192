import { Link } from "react-router-dom";
import Page from "../components/page/Page.js";
import { LargeSpacer, SmallSpacer } from "../components/Spacer/Spacer.js";
import ResumePDF from "../files/Resume_Current_cs.pdf";
import { useEffect, useState } from "react";
import Button from "../components/Button/Button.js";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function Resume() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Page title="Resume">
            <a
                style={{
                    border: "none",
                    color: "var(--textColor)",
                    backgroundColor: "var(--backgroundColor3)",
                    margin: "6px",
                    textAlign: "center",
                    textDecoration: "none",
                    fontFamily: "var(--sansSerif)",
                    fontSize: "var(--regularFontSize)",
                    borderRadius: "10px",
                    boxShadow: "0 0 6px #00000010",
                    padding: "12px 24px",
                }}
                href={ResumePDF}
                download
            >
                Download PDF
            </a>

            <LargeSpacer />

            <h2>Education</h2>
            <br />

            <h3>Gonzaga University | December 2025</h3>
            <p>
                <small>
                    Pursuing B.S. in Computer Science, expected graduation in
                    December 2025
                </small>
            </p>
            <ul style={{ margin: 2 }}>
                <li>
                    <p>4.00 GPA</p>
                </li>
                <li>
                    <p>Honors Program member and President’s List Member</p>
                </li>
            </ul>
            <br />

            <h3>Las Positas College | August 2023</h3>
            <p>
                <small>
                    Received A.A. degree in liberal arts, emphasis on math and
                    science
                </small>
            </p>
            <ul style={{ margin: 2 }}>
                <li>
                    <p>3.59 GPA</p>
                </li>
                <li>
                    <p>
                        Graduated with Highest Honors and Academic Honor List
                        status
                    </p>
                </li>
                <li>
                    <p>Completed four honors projects</p>
                </li>
            </ul>

            <SmallSpacer />

            <h2>Programming Skills</h2>
            <ul style={{ margin: 2 }}>
                <li>
                    <p>Languages: C, C++, Java, JavaScript, Swift</p>
                </li>
                <li>
                    <p>
                        Frameworks & Tools: React, Node.JS, Express.JS, SwiftUI
                    </p>
                </li>
                <li>
                    <p>
                        iOS ecosystem mobile app development with Swift and
                        SwiftUI
                    </p>
                </li>
                <li>
                    <p>
                        Web development in JavaScript with Express backend and
                        React frontend
                    </p>
                </li>
                <li>
                    <p>OAuth 2.0 and REST APIs</p>
                </li>
                <li>
                    <p>
                        Database integration with MySQL, server hosting on AWS
                    </p>
                </li>
            </ul>
            <br />

            <SmallSpacer />

            <h2>Work Experience</h2>
            <br />

            <h3>UExamS | 2024</h3>
            <p>
                <small>
                    Center Supervisor and Proctor for SAT Administration
                </small>
            </p>
            <ul style={{ margin: 2 }}>
                <li>
                    <p>
                        On-site supervisor for SAT proctoring staﬀ, managing
                        time sheets, liaising with remote leadership team,
                        answering staﬀ questions, proctoring SAT exam
                    </p>
                </li>
            </ul>
            <br />

            <h3>Painted Tongue Press | 2018 - 2022</h3>
            <p>
                <small>In-House Sales Representative</small>
            </p>
            <ul style={{ margin: 2 }}>
                <li>
                    <p>
                        Experience in sales, social media marketing, cold
                        calling, finding prospective clients, client relations,
                        market research, and facilitating bespoke customer
                        experiences
                    </p>
                </li>
            </ul>
            <br />

            <h3>Las Positas College SAGA Club | 2022</h3>
            <p>
                <small>Founder and President</small>
            </p>
            <ul style={{ margin: 2 }}>
                <li>
                    <p>
                        Experience in leadership, communication, organizational
                        planning, and event hosting
                    </p>
                </li>
            </ul>
            <br />

            <SmallSpacer />

            <h2>Projects</h2>
            <br />
            <Button to="/projects">View Projects</Button>
        </Page>
    );
}

export default Resume;