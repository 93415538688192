import "./ButtonStyles.css";
import { useNavigate } from "react-router-dom";

function Button(props) {
    const navigate = useNavigate();
    function navigateTo(path) { navigate(path); }
    
    function backgroundColor() {
        if (props.backgroundColor) {
            return props.backgroundColor;
        } else if (props.buttonStyle == "plain" || props.buttonStyle == "underlined") {
            return "#00000000";
        } else {
            return "var(--backgroundColor3)";
        }
    }
    
    return (
        <button
            className={props.buttonStyle ?? "default"}
            style={{
                color: props.color ?? "var(--textColor)",
                backgroundColor: backgroundColor(),
                margin: props.margin ?? "6px"
            }}
            onClick={() => {
                if (props.action) {
                    props.action();
                } else if (props.to) {
                    navigateTo(props.to);
                }
            }}
        >
            {props.children ?? props.title}
        </button>
    );
}
 export default Button;