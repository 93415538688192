import Page from "../components/page/Page.js";
import { Link } from "react-router-dom";
import localURL from '../localURL.json';
import useDetectMobile from "../hooks/useDetectMobile.js";

function SquareImage({ name }) {
    const { isMobile } = useDetectMobile();
    const size = () => {
        return isMobile ? "60%" : "calc(33% - 12px)";
    }
    
    return (
        <img
            src={localURL.api + "/assets/" + name}
            style={{
                objectFit: "cover",
                display: "inline-block",
                width: size(),
                height: size(),
                padding: "6px",
                borderRadius: "15px",
            }}
        />
    );
}

function CarouselImage({ name }) {
    const { isMobile } = useDetectMobile();
    
    return (
        <>
            {isMobile ? (
                <img
                    src={localURL.api + "/assets/" + name}
                    style={{
                        display: "inline-block",
                        margin: "6px 0",
                        maxWidth: "100%",
                        objectFit: "cover",
                        borderRadius: "15px",
                        boxShadow: "var(--shadow)",
                    }}
                />
            ) : (
                <img
                    src={localURL.api + "/assets/" + name}
                    style={{
                        objectFit: "cover",
                        display: "inline-block",
                        maxHeight: 250,
                        margin: "6px",
                        borderRadius: "15px",
                        boxShadow: "var(--shadow)",
                    }}
                />
            )}
        </>
    );
}

function Home() {
    const { isMobile } = useDetectMobile();

    return (
        <Page title="Mark Reggiardo">
            <p>
                Welcome to my website! My name is Mark and I'm a computer
                science major at Gonzaga University.
            </p>
            <br />
            <p>
                Please feel free to check out some of my{" "}
                <Link to="/projects">projects</Link>, take a look at my{" "}
                <Link to="/resume">resume</Link>, or keep reading to learn more
                about me.
            </p>
            <br />

            <div
                style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: 0,
                    overflowX: isMobile ? "auto" : "hidden",
                }}
            >
                <SquareImage name="meWithHoney.jpeg" />
                <SquareImage name="sunsetSelfie.jpeg" />
                <SquareImage
                    style={{ paddingRight: 0 }}
                    name="meWithBiscuit.jpeg"
                />
            </div>
            <div style={{ textAlign: "center" }}>
                <p>
                    <small>
                        Me with my family's two lovely dogs: Honey (left) and
                        Biscuit (right)
                    </small>
                </p>
            </div>

            <br />
            <p>
                I am studying computer science so that I can work in the fields
                of augmented soundscape engineering and accessibility technology
                to help make technology that has the power to change lives and
                bring new capabilities to people.
            </p>
            <br />
            <p>
                In my free time, I like to hike, hit the gym, visit a beatiful
                spot in nature, or listen to music. Here are some of my favorite
                photos from my camera roll.
            </p>
            <br />

            <div
                style={{
                    display: "table-cell",
                    width: "100%",
                    marginBottom: 0,
                    textAlign: "center"
                }}
            >
                <CarouselImage name="mountainAerial.jpeg" />
                <CarouselImage name="northernLights.jpeg" />
                <CarouselImage name="flower.jpeg" />
                <CarouselImage name="lakeChabot.jpeg" />
                <CarouselImage name="pavillion.jpeg" />
                <CarouselImage name="sunset.jpeg" />
                <CarouselImage name="biscuit.jpeg" />
                <CarouselImage style={{ paddingRight: 0 }} name="honey.jpeg" />
            </div>
        </Page>
    );
}

export default Home;
