import { Spacer } from "../components/Spacer/Spacer.js";
import Page from "../components/page/Page.js";
import { useState, useEffect, Children } from "react";
import UserManager from "../UserManager.js";
import { TableList, Cell } from "../components/tableList/TableList.js";
import ChevronUpIcon from "../icons/chevronUpIcon.js";
import ChevronDownIcon from "../icons/chevronDownIcon.js";
import Button from "../components/Button/Button.js";
import { formatDate } from "../tools/formatDate.js";

const sortByFirstName = (a, b) => {
    const a1 = new String(a.name.first + a.name.last).toLowerCase();
    const b1 = new String(b.name.first + b.name.last).toLowerCase();
    return a1 < b1;
};

const sortByLastName = (a, b) => {
    const a1 = new String(a.name.last + a.name.first).toLowerCase();
    const b1 = new String(b.name.last + b.name.first).toLowerCase();
    return a1 < b1;
};

const sortByEmail = (a, b) => {
    const a1 = new String(a.email).toLowerCase();
    const b1 = new String(b.email).toLowerCase();
    return a1 < b1;
};

const sortByDate = (a, b) => {
    return new Date(a.date) < new Date(b.date);
};

function MangeUsers() {
    var [users, setUsers] = useState([]);
    var [sort, setSort] = useState("date");
    var [sortDescending, setSortDescending] = useState(true);
    var [thisUser, setThisUser] = useState(null);

    const updateUsers = () => {
        UserManager.getUserList().then((result) => {
            if (result) {
                let parsedResult = JSON.parse(result);
                let array = Array.from(parsedResult);
                let newUsers = array.map((user) => {
                    var role = new String(Array.from(user.scopes).slice(-1));
                    role = role.charAt(0).toUpperCase() + role.slice(1);
                    return {
                        name: user.name,
                        email: user.email,
                        date: new Date(user.createdAt).toString(),
                        role: role,
                        accessToken: user.accessToken,
                    };
                });
                setUsers(newUsers);
            }
        });

        UserManager.getStoredUser().then((storedUser) => {
            setThisUser(storedUser);
        });
    };

    useEffect(() => {
        updateUsers();
    }, []);

    const sorts = {
        firstName: sortByFirstName,
        lastName: sortByLastName,
        email: sortByEmail,
        date: sortByDate,
    };

    function SortButton({ title, sortKey }) {
        return (
            <button
                className="primaryButton groupedItem"
                style={{
                    color: "var(--textColor)",
                    backgroundColor:
                        sort == sortKey
                            ? "var(--backgroundColor3)"
                            : "var(--backgroundColor2)",
                    boxShadow: sort == sortKey ? "0 0 8px #00000020" : "none",
                }}
                onClick={() => setSort(sortKey)}
            >
                <p>{title}</p>
            </button>
        );
    }
    
    function UserManagementPanel({ content }) {
        return (
            <>
                {content.role == "User" && (
                    <Button
                        title="Remove User"
                        buttonStyle="small"
                        action={() => {
                            UserManager.removeUser(content.email).then(
                                updateUsers
                            );
                        }}
                    />
                )}

                {thisUser.scopes.includes("owner") && (
                    <>
                        {content.role == "Owner" ? (
                            <Button
                                title="Remove Owner"
                                buttonStyle="small"
                                action={() => {
                                    UserManager.removeOwner(content.email).then(
                                        updateUsers
                                    );
                                }}
                            />
                        ) : content.role == "Admin" ? (
                            <>
                                <Button
                                    title="Remove Admin"
                                    buttonStyle="small"
                                    className="grouped"
                                    action={() => {
                                        UserManager.removeAdmin(
                                            content.email
                                        ).then(updateUsers);
                                    }}
                                />

                                <Button
                                    title="Make Owner"
                                    buttonStyle="small"
                                    action={() => {
                                        UserManager.makeOwner(
                                            content.email
                                        ).then(updateUsers);
                                    }}
                                />
                            </>
                        ) : (
                            <Button
                                title="Make Admin"
                                buttonStyle="small"
                                action={() => {
                                    UserManager.makeAdmin(content.email).then(
                                        updateUsers
                                    );
                                }}
                            />
                        )}
                    </>
                )}
            </>
        );
    }
    
    function formatName(content) {
        return content.name.first + " " + content.name.last;
    }

    return (
        <Page title="User Management" authScope="owner">

            {users.length > 0 && (
                <>
                    <p style={{ margin: "0px", marginBottom: "12px" }}>Sort By</p>

                    <div
                        style={{
                            display: "table-row",
                        }}
                    >
                        <div
                            style={{
                                borderRadius: "12px",
                                backgroundColor: "var(--backgroundColor2)",
                                width: "fit-content",
                                boxShadow: "0px 0px 4px #00000020",
                                display: "table-cell",
                                paddingBottom: "8px",
                            }}
                        >
                            <SortButton
                                title="First Name"
                                sortKey="firstName"
                            />

                            <SortButton title="Last Name" sortKey="lastName" />

                            <SortButton title="Email" sortKey="email" />

                            <SortButton title="Date" sortKey="date" />
                        </div>

                        <div>
                            <button
                                className="primaryButton"
                                style={{
                                    backgroundColor: "var(--backgroundColor2)",
                                    boxShadow: "0px 0px 4px #00000020",
                                    borderRadius: "20px",
                                    marginLeft: "12px",
                                    padding: "12px",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    display: "table-cell",
                                    borderRadius: "24px",
                                }}
                                onClick={() =>
                                    setSortDescending(!sortDescending)
                                }
                            >
                                {sortDescending ? (
                                    <ChevronDownIcon
                                        fill="var(--textColor)"
                                        size="18px"
                                    />
                                ) : (
                                    <ChevronUpIcon
                                        fill="var(--textColor)"
                                        size="18px"
                                    />
                                )}
                            </button>
                        </div>
                    </div>

                    <Spacer height="12px" />

                    <TableList
                        headers={[
                            "Name",
                            "Email",
                            "Date Created",
                            "Role",
                            "Actions",
                        ]}
                        data={users}
                        sort={sorts[sort]}
                        reverseSort={sortDescending}
                        tableRow={({ content }) => {
                            return (
                                <>
                                    <Cell>{formatName(content)}</Cell>
                                    <Cell>{content.email}</Cell>
                                    <Cell>
                                        {formatDate(
                                            content.date,
                                            "MMM do, YYYY, h:mm:ssa"
                                        )}
                                    </Cell>
                                    <Cell>{content.role}</Cell>
                                    <td>
                                        <UserManagementPanel
                                            content={content}
                                        />
                                    </td>
                                </>
                            );
                        }}
                        listRow={({ content }) => {
                            return (
                                <>
                                    <p><b>{formatName(content)}</b></p>
                                    <p>{content.email}</p>
                                    <p>
                                        {formatDate(
                                            content.date,
                                            "MMM do, YYYY, h:mm:ssa"
                                        )}
                                    </p>
                                    <p>{content.role}</p>

                                    <UserManagementPanel content={content} />
                                </>
                            );
                        }}
                    />
                </>
            )}
        </Page>
    );
}

export default MangeUsers;
