import { formatDate } from "../tools/formatDate.js";
import localURL from "../localURL.json";
import useDetectMobile from "../hooks/useDetectMobile.js";
import Button from "../components/Button/Button.js";
import { useEffect, useRef, useState } from "react";

function truncateIfNeeded(text, limit) {
    if (text.length > limit && limit > 3) {
        return text.substring(0, limit - 3) + "...";
    } else {
        return text;
    }
}

function ProjectCard({ project }) {
    const { isMobile } = useDetectMobile();
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    
    useEffect(() => {
        setSize({
            width: ref?.current?.clientWidth ?? 0,
            height: ref?.current?.clientHeight ?? 0
        });
    })
    
    return (
        <div
            style={
                isMobile
                    ? {
                          display: "block",
                          position: "relative",
                      }
                    : {
                          display: "flex",
                          position: "relative",
                          minHeight: size.height
                      }
            }
        >
            <div>
                <p>
                    <b>{project.title}</b>
                </p>
                <p>{truncateIfNeeded(project.info, 300)}</p>
                <p>
                    <small>
                        {project.startDate
                            ? `${formatDate(project.startDate)} - `
                            : ""}{" "}
                        {formatDate(project.endDate)}
                    </small>
                </p>

                {!isMobile && (
                    <Button
                        to={`/project/${project.id}`}
                        title="More"
                        color="var(--cyan)"
                        buttonStyle="small"
                    />
                )}
            </div>
            <div
                style={{
                    marginTop: isMobile ? "12px" : "0",
                    marginLeft: isMobile ? 0 : size.width
                }}
            >
                {project.images.length > 0 && (
                    <>
                        {isMobile ? (
                            <div style={{ padding: 12 }}>
                                <img
                                    ref={ref}
                                    src={
                                        localURL.api +
                                        "/assets/" +
                                        project.images[0]
                                    }
                                    style={{
                                        width: "100%",
                                        zIndex: "1",
                                        borderRadius: "8px",
                                        objectFit: "contain",
                                    }}
                                />
                            </div>
                        ) : (
                            <img
                                ref={ref}
                                src={
                                    localURL.api +
                                    "/assets/" +
                                    project.images[0]
                                }
                                style={{
                                    maxHeight: "350px",
                                    maxWidth: "300px",
                                    marginRight: "6px",
                                    zIndex: "1",
                                    borderRadius: "8px",
                                    marginLeft: "12px",
                                    marginRight: "auto",
                                    objectFit: "contain",
                                    position: "absolute",
                                    right: 0,
                                }}
                            />
                        )}
                    </>
                )}
            </div>

            {isMobile && (
                <div style={{ marginTop: "12px" }}>
                    <Button
                        to={`/project/${project.id}`}
                        title="More"
                        color="var(--cyan)"
                        buttonStyle="small"
                        margin="0"
                    />
                </div>
            )}
        </div>
    );
}

export default ProjectCard;