import { useEffect, useState } from "react";
import Page from "../components/page/Page.js";
import { useParams } from "react-router-dom";
import { LargeSpacer, SmallSpacer } from "../components/Spacer/Spacer.js";
import Button from "../components/Button/Button.js";
import { formatDate } from "../tools/formatDate.js";
import { getProjectInfo } from "./projectEditor/ProjectManager.js";
import localURL from '../localURL.json';
import useDetectMobile from "../hooks/useDetectMobile.js";

function Project() {
    const params = useParams();
    const id = params.id;
    const [project, setProject] = useState(null);
    const [title, setTitle] = useState("Project");
    const { isMobile } = useDetectMobile();
    
    useEffect(() => {
        getProjectInfo(id).then((info) => {
            setProject(info);
            setTitle(info?.title ?? "Project");
            console.log(project, localURL.api);
        });
    }, []);
        
    return (
        <Page title={title}>
            <>
                {project != null ? (
                    <>
                        {project.images && (
                            <div
                                style={{
                                    display: "block",
                                    display: "flex",
                                    maxHeight: "500px",
                                    overflowX: "auto",
                                }}
                            >
                                {Array.from(project.images).map((image) => (
                                    <img
                                        src={
                                            localURL.api +
                                            "/assets/" +
                                            image
                                        }
                                        style={{
                                            objectFit: "cover",
                                            maxHeight: isMobile ? "300px" : "500px",
                                            marginRight: "6px",
                                            zIndex: "1",
                                            borderRadius: "8px",
                                            boxShadow: "var(--shadow)",
                                            margin: 6
                                        }}
                                    />
                                ))}
                            </div>
                        )}

                        <SmallSpacer />

                        <p>{project.info}</p>
                        <p>
                            <small>
                                {project.startDate
                                    ? `${formatDate(project.startDate)} - `
                                    : ""}{" "}
                                {formatDate(project.endDate)}
                            </small>
                        </p>

                        <LargeSpacer />

                        <Button
                            title="< Project List"
                            to="/projects"
                            buttonStyle="small"
                        />
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </>
        </Page>
    );
}

export default Project;
