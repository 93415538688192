import React from "react";

function ChevronDownIcon({ fill, size }) {
    return (
        <svg
            fill={fill}
            width={size}
            viewBox="0 0 720 410"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Rounded-Rectangle"
                fill-rule="evenodd"
                stroke="none"
                d="M 16.845238 15.634552 C 36.371452 -3.891663 68.029701 -3.891663 87.555916 15.634552 L 395.134583 323.213196 C 414.660767 342.73941 414.660797 374.397705 395.134583 393.92392 C 375.608368 413.450134 343.950134 413.450134 324.42392 393.92392 L 16.845238 86.345245 C -2.680976 66.819031 -2.680975 35.160767 16.845238 15.634552 Z"
            />
            <path
                id="Rounded-Rectangle-copy"
                fill-rule="evenodd"
                stroke="none"
                d="M 325.365448 394.423889 C 305.839233 374.897675 305.839233 343.23941 325.365448 323.713196 L 632.93396 16.144684 C 652.460205 -3.381561 684.118408 -3.381531 703.644653 16.144684 C 723.170837 35.670898 723.170898 67.329132 703.644653 86.855347 L 396.076111 394.423889 C 376.549896 413.950104 344.891663 413.950104 325.365448 394.423889 Z"
            />
        </svg>
    );
}

export default ChevronDownIcon;
