import "./DatePicker.css"

function DatePicker(props) {
    return (
        <input
            className="datePicker"
            type="date"
            name={props.name}
            value={props.value}
            min={props.minValue}
            max={props.maxValue}
            onChange={(event) => {
                props.onChange(event);
            }}
        />
    );
}

export default DatePicker;