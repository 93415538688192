import { useEffect, useState } from "react";
import Page from "../components/page/Page.js";
import UserManager from "../UserManager.js";
import { Link } from "react-router-dom";
import { LargeSpacer, SmallSpacer } from "../components/Spacer/Spacer.js";
import { useNavigate } from "react-router-dom";
import { TextField } from "../components/TextEntry/TextEntry.js";

function Login() {
    var [isLoaded, setIsLoaded] = useState(false);
    var [email, setEmail] = useState("");
    var [password, setPassword] = useState("");
    var [errorText, setErrorText] = useState("");

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    useEffect(() => {
        UserManager.getStoredUser().then((storedUser) => {
            if (storedUser) {
                navigateTo("/dashboard");
            } else {
                setIsLoaded(true);
            }
        });
    });
    
    const login = async () => {
        const response = await UserManager.login(
            email,
            password
        );
        if ((response, !response.error)) {
            navigateTo("/dashboard");
            window.location.reload();
        } else {
            setErrorText("Error logging in");
        }
    }

    return (
        <Page title="Login">
            {isLoaded && (
                <div
                    style={{
                        maxWidth: "400px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                    onKeyDown={async (event) => {
                        if (event.key === "Enter") {
                            if (email != "" && password != "") {
                                await login();
                            }
                        }
                    }}
                >
                    <LargeSpacer />

                    <div
                        style={{
                            backgroundColor: "var(--backgroundColor2)",
                            padding: "26px",
                            borderRadius: "24px",
                            boxShadow:
                                "-0.5px -0.5px 1px #00000033 inset, 0.5px 0.5px 1px #ffffff33 inset, 0 0 16px #00000020",
                        }}
                    >
                        <p>Email</p>
                        <TextField
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />

                        <SmallSpacer />

                        <p>Password</p>
                        <TextField
                            type="password"
                            value={password}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                        />

                        <LargeSpacer />

                        <div style={{ height: "46px", paddingBottom: "12px" }}>
                            <Link
                                className="secondaryLink"
                                style={{
                                    float: "left",
                                    color: "var(--cyan)",
                                    paddingTop: "18px",
                                }}
                                to="/signup"
                            >
                                <b>Create Account</b>
                            </Link>

                            <button
                                className="primaryButton"
                                onClick={async () => login()}
                                style={{
                                    float: "right",
                                    color: "white",
                                    backgroundColor: "var(--cyan)",
                                    right: "0px",
                                }}
                            >
                                Log In
                            </button>
                        </div>
                    </div>

                    <LargeSpacer />

                    <p>{errorText}</p>

                    <LargeSpacer />
                </div>
            )}
        </Page>
    );
}

export default Login;
