import { Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar.js";
import Home from "./pages/home.js";
import Projects from "./pages/projects.js";
import Resume from './pages/resume.js';
import Login from "./pages/login.js";
import Signup from "./pages/signup.js";
import UserHome from "./pages/dashboard.js";
import ManageUsers from "./pages/manageUsers.js";
import ManageProjects from "./pages/manageProjects.js";
import ProjectEditor from "./pages/projectEditor/ProjectEditor.js";
import Project from "./pages/project.js";
import Contact from "./pages/Contact.js";


export default function App() {    
    return (
        <>
            <Navbar />
            <>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/resume" element={<Resume />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/dashboard" element={<UserHome />} />
                    <Route path="/manageUsers" element={<ManageUsers />} />
                    <Route path="/manageProjects" element={<ManageProjects />} />
                    <Route path="/project/:id" element={<Project />} />
                    <Route path="/editProject/:id" element={<ProjectEditor />} />
                    <Route path="/newProject" element={<ProjectEditor />} />
                </Routes>
            </>
        </>
    );
}
