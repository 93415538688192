import useDetectMobile from "../../hooks/useDetectMobile.js";
import "./TableList.css";

function TableList(props) {
    const { isMobile } = useDetectMobile();
    
    const sortedData = () => {
        var data = Array.from(props.data);
        const sort = props.sort;
        const reverseSort = props.reverseSort;
        
        if (sort) {
            data.sort(sort);
            
            if (reverseSort) {
                data.reverse();
            }
        }
        
        return data;
    }
    
    return (
        <>
            {isMobile ? (
                <ul className="tableListList">
                    {sortedData().map((item) => (
                        <li className="tableListListRow" key={item.email}>
                            <props.listRow content={item} />
                        </li>
                    ))}
                </ul>
            ) : (
                <table>
                    <thead>
                        <tr className="headerRow">
                            {Array.from(props.headers).map((header) => (
                                <th>
                                    <p>
                                        <b>{header}</b>
                                    </p>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {sortedData().map((item) => (
                            <tr key={item.email}>
                                <props.tableRow content={item} />
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
}

function Cell({ children }) {
    return (
        <td>
            <p>
                {children}
            </p>
        </td>
    );
}

export { TableList, Cell };