import React from "react";

function CloseIcon({ fill, size }) {
    return (
        <svg
            fill={fill}
            width={size}
            height={size}
            viewBox="0 0 720 720"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Rounded-Rectangle"
                fill-rule="evenodd"
                stroke="none"
                d="M 17.355339 702.913757 C 36.881554 722.440002 68.539803 722.440002 88.066017 702.913757 L 702.913818 88.065979 C 722.440002 68.539795 722.440002 36.881531 702.913818 17.355347 C 693.536987 7.978516 680.819275 2.710632 667.558472 2.710632 C 654.297607 2.710693 641.579895 7.978516 632.203125 17.355347 L 17.355339 632.203125 C 7.978519 641.579956 2.710681 654.297607 2.710678 667.558472 C 2.710676 680.819275 7.978519 693.536987 17.355339 702.913757 Z"
            />
            <path
                id="Rounded-Rectangle-copy"
                fill-rule="evenodd"
                stroke="none"
                d="M 17.08622 17.355347 C -2.439994 36.881531 -2.439994 68.539795 17.08622 88.06604 L 631.934021 702.913818 C 651.460205 722.440002 683.118469 722.440002 702.644653 702.913818 C 722.170898 683.387573 722.170898 651.72937 702.644653 632.203125 L 87.796898 17.355347 C 68.270683 -2.170898 36.612434 -2.170898 17.08622 17.355347 Z"
            />
        </svg>
    );
}

export default CloseIcon;