import { useState, useEffect } from 'react';
import NavbarLink from './NavbarLink.js';
import './NavbarStyles.css';
import MenuIcon from "../../icons/MenuIcon.js";
import CloseIcon from '../../icons/CloseIcon.js';
import UserManager from '../../UserManager.js';
import useDetectMobile from '../../hooks/useDetectMobile.js';

function Navbar() {
    var [isCollapsed, setIsCollapsed] = useState(true);
    const { isMobile, screenClass } = useDetectMobile();
    const expandedClass = isCollapsed ? "collapsed" : "expanded";
    var [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        UserManager.getStoredUser().then((storedUser) => {
            if (storedUser) {
                setIsLoggedIn(true);
            }
        });
    });
    
    return (
        <>
            {isMobile && (
                <button
                    className={`collapseButton ${expandedClass}`}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed && (
                        <MenuIcon fill="var(--textColor)" size="28px" />
                    )}
                    {!isCollapsed && (
                        <CloseIcon fill="var(--textColor)" size="20px" />
                    )}
                </button>
            )}

            <nav className={`navbar ${screenClass} ${expandedClass}`}>
                <ul className={`navbarList ${screenClass}`}>
                    <NavbarLink to="/" onClick={() => setIsCollapsed(true)}>
                        <p>Home</p>
                    </NavbarLink>

                    <NavbarLink
                        to="/projects"
                        onClick={() => setIsCollapsed(true)}
                    >
                        <p>Projects</p>
                    </NavbarLink>

                    <NavbarLink
                        to="/resume"
                        onClick={() => setIsCollapsed(true)}
                    >
                        <p>Resume</p>
                    </NavbarLink>
                    
                    <NavbarLink
                    to="/contact"
                    onClick={() => setIsCollapsed(true)}
                    >
                        <p>Contact</p>
                        </NavbarLink>

                    {isLoggedIn ? (
                        <NavbarLink
                            to="/dashboard"
                            onClick={() => setIsCollapsed(true)}
                        >
                            <p>Dashboard</p>
                        </NavbarLink>
                    ) : (
                        <NavbarLink
                            to="/login"
                            onClick={() => setIsCollapsed(true)}
                        >
                            <p>Login</p>
                        </NavbarLink>
                    )}
                </ul>
            </nav>
        </>
    );
}

export default Navbar;