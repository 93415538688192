import moment from "moment";

function adjustDateForTimeZone(date) {
    if (date) {
        const epochTime = new Date(date).getTime();
        const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const adjustedTime = epochTime + timeZoneOffset;
        return new Date(adjustedTime);
    } else {
        return null;
    }
}

function formatDate(date, format) {
    if (date) {
        const adujustedDate = adjustDateForTimeZone(date);
        
        if (adujustedDate) {
            return moment(adujustedDate).format(format ?? "MMMM Do, YYYY");
        }
    }
    
    return null;
}

export { formatDate, adjustDateForTimeZone };