import React from "react";

function ChevronUpIcon({ fill, size }) {
    return (
        <svg
            fill={fill}
            width={size}
            viewBox="0 0 720 410"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Rounded-Rectangle"
                fill-rule="evenodd"
                stroke="none"
                d="M 16.845238 394.423889 C 36.371452 413.950104 68.029701 413.950104 87.555916 394.423889 L 395.134583 86.845215 C 414.660767 67.319031 414.660797 35.660736 395.134583 16.134521 C 375.608368 -3.391693 343.950134 -3.391693 324.42392 16.134521 L 16.845238 323.713196 C -2.680976 343.23941 -2.680975 374.897675 16.845238 394.423889 Z"
            />
            <path
                id="Rounded-Rectangle-copy"
                fill-rule="evenodd"
                stroke="none"
                d="M 324.365448 15.634552 C 304.839233 35.160767 304.839233 66.819031 324.365448 86.345245 L 631.93396 393.913757 C 651.460205 413.440002 683.118408 413.439972 702.644653 393.913757 C 722.170837 374.387573 722.170898 342.729309 702.644653 323.203094 L 395.076111 15.634552 C 375.549896 -3.891663 343.891663 -3.891663 324.365448 15.634552 Z"
            />
        </svg>
    );
}

export default ChevronUpIcon;
