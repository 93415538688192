import { useEffect, useState } from "react";
import Page from "../components/page/Page.js";
import List from "../components/tableList/List.js";
import ProjectCard from "./ProjectCard.js";
import Button from "../components/Button/Button.js";
import { deleteProject, getProjectList } from "./projectEditor/ProjectManager.js";

function ManageProjects() {
    
    const [list, setList] = useState([]);
    
    useEffect(() => {
        getProjectList().then(list => setList(list));
    }, [])
    
    const updateList = () => {
        getProjectList().then(newList => setList(newList));
    }
    
    return (
        <Page title="Manage Projects" authScope="admin">
            <Button
                title="Add Project"
                to="/newProject"
                color="var(--green)"
            />
            
            <List
                title="Projects"
                data={list}
                sort={(a, b) => {
                    return new Date(a.endDate) < new Date(b.endDate);
                }}
                listRow={({ content }) => {
                    return (
                        <>
                            <ProjectCard project={content} />

                            <Button
                                to={`/editProject/${content.id}`}
                                title="Edit"
                                buttonStyle="small"
                                margin="0"
                            />

                            <Button
                                title="Delete"
                                buttonStyle="small"
                                color="var(--red)"
                                action={() =>
                                    deleteProject(content.id).then(updateList)
                                }
                            />
                        </>
                    );
                }}
            />
        </Page>
    );
}

export default ManageProjects;
