import useWindowSize from "./useWindowSize.js";
import { isMobile } from "react-device-detect";

function useDetectMobile() {
    const { width } = useWindowSize();
    const useMobile = isMobile || width < 650;
    const screenClass = useMobile ? "mobile" : "desktop";
    
    return {
        isMobile: useMobile,
        screenClass: screenClass,
    };
}

export default useDetectMobile;
