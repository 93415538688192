import { useEffect, useState } from "react";
import Page from "../components/page/Page.js";
import UserManager from "../UserManager.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TextField } from "../components/TextEntry/TextEntry.js";
import { LargeSpacer, SmallSpacer } from "../components/Spacer/Spacer.js";

function Signup() {
    var [firstName, setFirstName] = useState("");
    var [lastName, setLastName] = useState("");
    var [email, setEmail] = useState("");
    var [password, setpassword] = useState("");
    var [errorText, setErrorText] = useState("");

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    const getUser = async () => {
        if (await UserManager.getStoredUser()) {
            navigateTo("/dashboard");
        }
    };

    useEffect(() => {
        getUser();
    }, []);
    
    const signup = async () => {
        if (
            await UserManager.signup({
                name: {
                    first: firstName,
                    last: lastName,
                },
                email: email,
                password: password,
            })
        ) {
            navigateTo("/dashboard");
            window.location.reload();
        } else {
            setErrorText("Error signing up");
        }
    };

    return (
        <Page title="Create Account">
            <div
                style={{
                    maxWidth: "400px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                onKeyDown={async (event) => {
                    if (event.key === "Enter") {
                        if (firstName && lastName && email && password) {
                            await signup();
                        }
                    }
                }}
            >
                <LargeSpacer />

                <div
                    style={{
                        backgroundColor: "var(--backgroundColor2)",
                        padding: "26px",
                        borderRadius: "24px",
                        boxShadow:
                            "-0.5px -0.5px 1px #00000033 inset, 0.5px 0.5px 1px #ffffff33 inset, 0 0 16px #00000020",
                    }}
                >
                    <p>First Name</p>
                    <TextField
                        type="text"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    />

                    <SmallSpacer />

                    <p>Last Name</p>
                    <TextField
                        type="text"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />

                    <SmallSpacer />

                    <p>Email</p>
                    <TextField
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />

                    <SmallSpacer />

                    <p>Password</p>
                    <TextField
                        type="password"
                        value={password}
                        onChange={(event) => setpassword(event.target.value)}
                    />

                    <LargeSpacer />

                    <div style={{ height: "46px", paddingBottom: "12px" }}>
                        <Link
                            className="secondaryLink"
                            style={{
                                float: "left",
                                color: "var(--green)",
                                paddingTop: "18px",
                            }}
                            to="/login"
                        >
                            <b>Log In</b>
                        </Link>

                        <button
                            className="primaryButton"
                            onClick={async () => signup()}
                            style={{
                                float: "right",
                                color: "white",
                                backgroundColor: "var(--green)",
                                right: "0px",
                            }}
                        >
                            Sign Up
                        </button>
                    </div>
                </div>

                <LargeSpacer />

                <p>{errorText}</p>

                <LargeSpacer />
            </div>
        </Page>
    );
}

export default Signup;
