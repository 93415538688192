import localURL from "./localURL.json";

class UserManager {
    static #localStorageKey = "login.info";

    static async signup(obj) {
        const response = await UserManager.#makeSignupRequest(obj);

        if (response) {
            UserManager.saveUser(response);
            return response;
        } else {
            return null;
        }
    }

    static async login(email, password) {
        const response = await UserManager.#makeLoginRequest(email, password);

        if (response) {
            UserManager.saveUser(response);
            return response;
        } else {
            return null;
        }
    }

    static async logout() {
        const user = await UserManager.getStoredUser();

        if (user) {
            try {
                if (
                    await UserManager.#makeLogoutRequest(
                        user.email,
                        user.accessToken
                    )
                ) {
                    UserManager.clearUser();
                    return true;
                } else {
                    throw new Error("Could not log user out");
                }
            } catch (error) {
                console.err(error);
                return false;
            }
        }
    }

    static async deleteUser() {
        const user = await UserManager.getStoredUser();
        await UserManager.#makeDeleteUserRequest(user.email, user.accessToken);
        UserManager.clearUser();
    }

    static async getStoredUser() {
        const storedUser = JSON.parse(
            localStorage.getItem(UserManager.#localStorageKey)
        );

        if (this.#areAllUserKeysPresent(storedUser)) {
            if (storedUser.accessTokenExpiration <= Date.now() * 1000) {
                if (storedUser.refreshTokenExpiration <= Date.now() * 1000) {
                    return await UserManager.#refreshAccessToken(
                        storedUser.email,
                        storedUser.refreshToken
                    );
                } else {
                    return null;
                }
            } else {
                return storedUser;
            }
        } else {
            return null;
        }
    }

    static saveUser(user) {
        localStorage.setItem(
            UserManager.#localStorageKey,
            JSON.stringify(user)
        );
    }

    static clearUser() {
        localStorage.setItem(UserManager.#localStorageKey, null);
        localStorage.clear(UserManager.#localStorageKey);
    }

    static async getUserList() {
        const user = await UserManager.getStoredUser();
        const response = await UserManager.#makeGetUserListRequest(
            user.email,
            user.accessToken
        );
        return response;
    }

    static async removeUser(email) {
        await this.#scopeRequest(email, "/user/remove", "DELETE");
    }

    static async makeAdmin(email) {
        await this.#scopeRequest(email, "/user/makeAdmin");
    }

    static async removeAdmin(email) {
        await this.#scopeRequest(email, "/user/removeAdmin");
    }

    static async makeOwner(email) {
        await this.#scopeRequest(email, "/user/makeOwner");
    }

    static async removeOwner(email) {
        await this.#scopeRequest(email, "/user/removeOwner");
    }

    static async #scopeRequest(email, path, method) {
        const user = await UserManager.getStoredUser();

        await fetch(localURL.api + path, {
            method: method ?? "POST",
            headers: {
                "Content-Type": "application/json",
                From: user.email,
                Authorization: `Bearer ${user.accessToken}`,
            },
            body: JSON.stringify({
                target: email
            }),
        });
    }

    static async #refreshAccessToken(email, refreshToken) {
        const response = await fetch(localURL.api + "/auth/refresh", {
            method: "POST",
            headers: {
                From: email,
                Authorization: `Bearer ${refreshToken}`,
            },
        });
        return response.json();
    }

    static #areAllUserKeysPresent(user) {
        return (
            user &&
            user.email &&
            user.name &&
            user.accessToken &&
            user.accessTokenExpiration &&
            user.refreshToken &&
            user.refreshTokenExpiration &&
            user.scopes &&
            user.createdAt
        );
    }

    static async #makeSignupRequest(obj) {
        const response = await fetch(localURL.api + "/auth/signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                From: obj.email,
                Authorization: obj.password,
            },
            body: JSON.stringify({
                firstName: obj.name.first,
                lastName: obj.name.last,
            }),
        });
        return response.json();
    }

    static async #makeLoginRequest(email, password) {
        const response = await fetch(localURL.api + "/auth/login", {
            method: "POST",
            headers: {
                From: email,
                Authorization: password,
            },
        });

        return response.json();
    }

    static async #makeLogoutRequest(email, accessToken) {
        const response = await fetch(localURL.api + "/auth/logout", {
            method: "POST",
            headers: {
                From: email,
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.json();
    }

    static async #makeDeleteUserRequest(email, accessToken) {
        const response = await fetch(localURL.api + "/auth/delete", {
            method: "DELETE",
            headers: {
                From: email,
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.json();
    }

    static async #makeGetUserListRequest(email, accessToken) {
        const response = await fetch(localURL.api + "/user/list", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                From: email,
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.json();
    }
}

export default UserManager;
