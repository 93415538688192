import localURL from "../../localURL.json";
import UserManager from "../../UserManager.js";

async function uploadImage(image) {
    const user = await UserManager.getStoredUser();
    
    var data = new FormData();
    data.append("image", image);
    
    const imageID = await fetch(localURL.api + "/asset/add", {
        method: "POST",
        headers: {
            From: user.email,
            Authorization: `Bearer ${user.accessToken}`,
        },
        body: data,
    });
    
    return imageID.json();;
}

async function deleteImage(id) {
    const user = await UserManager.getStoredUser();
    
    await fetch(localURL.api + "/asset/delete", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            From: user.email,
            Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ target: id }),
    });
}

async function getProjectInfo(id) {
    const response = await fetch(localURL.api + `/projects/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    
    return response.json();
}

async function deleteProject(id) {
    const user = await UserManager.getStoredUser();
    const project = await getProjectInfo(id);
        
    if (project.message) { return; }
    
    for await (var imageID of project.images) {
        await deleteImage(imageID);
    }

    await fetch(localURL.api + "/projects/remove", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            From: user.email,
            Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ target: id }),
    });    
}

async function addProject(project, images) {
    const user = await UserManager.getStoredUser();    
    const imagePaths = [];
    project.images = Array.from(project.images ?? []);
        
    for await (const image of images) {
        const imagePath = await uploadImage(image);
        project.images.push(imagePath.id);
    }

    await fetch(localURL.api + "/projects/add", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            From: user.email,
            Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ 
            project: project,
        })
    });
}

async function getProjectList() {
    const response = await fetch(localURL.api + "/projects/list", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    const json = response.json();
    return json;
}


export { getProjectInfo, deleteProject, addProject, getProjectList };