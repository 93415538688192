import { useEffect, useState } from "react";
import Page from "../components/page/Page.js";
import List from "../components/tableList/List.js";
import ProjectCard from "./ProjectCard.js";
import Button from "../components/Button/Button.js";
import { getProjectList } from "./projectEditor/ProjectManager.js";

function Projects() {
    
    const [list, setList] = useState([]);
    
    useEffect(() => {
        getProjectList().then(list => setList(list));
    }, [])
    
    return (
        <Page title="Projects">
            <>
                <p>Here is a list of my technical projects, both academic and personal.</p>

                <List
                    title="Projects"
                    data={list}
                    sort={(a, b) => {
                        return new Date(a.endDate) < new Date(b.endDate);
                    }}
                    listRow={({ content }) => <ProjectCard project={content} />}
                />
            </>
        </Page>
    );
}

export default Projects;
