import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './NavbarStyles.css';
import useDetectMobile from '../../hooks/useDetectMobile.js';

function NavbarLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    const activeClass = isActive ? "active" : "";
    const { screenClass } = useDetectMobile();

    return (
        <li className={`navbarListItem ${activeClass} ${screenClass}`}>
            <Link
                className={`navbarLink ${activeClass} ${screenClass}`}
                to={to}
                {...props}
            >
                {children}
            </Link>
        </li>
    );
}

export default NavbarLink;