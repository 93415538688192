import "./TableList.css";

function List(props) {
    
    const sortedData = () => {
        var data = Array.from(props.data);
        const sort = props.sort;
        const reverseSort = props.reverseSort;
        
        if (sort) {
            data.sort(sort);
            
            if (reverseSort) {
                data.reverse();
            }
        }
        
        return data;
    }
    
    return (
        <ul className="tableListList">
            {sortedData().map((item) => (
                <li className="tableListListRow" key={item.email}>
                    <props.listRow content={item} />
                </li>
            ))}
        </ul>
    );
}

export default List;