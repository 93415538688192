import React from "react";

function MenuIcon({ fill, size }) {
    return (
        <svg
            fill={fill}
            width={size}
            height={size}
            viewBox="0 0 720 720"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Rounded-Rectangle"
                fillRule="evenodd"
                stroke="none"
                d="M 0 145 C 0 172.614258 22.385763 195 50 195 L 670 195 C 697.614258 195 720 172.614258 720 145 C 720 117.385742 697.614258 95 670 95 L 50 95 C 22.385763 95 0 117.385742 0 145 Z"
            />
            <path
                id="Rounded-Rectangle-copy"
                fillRule="evenodd"
                stroke="none"
                d="M 0 360 C 0 387.614227 22.385763 410 50 410 L 670 410 C 697.614258 410 720 387.614227 720 360 C 720 332.385773 697.614258 310 670 310 L 50 310 C 22.385763 310 0 332.385773 0 360 Z"
            />
            <path
                id="Rounded-Rectangle-copy-2"
                fillRule="evenodd"
                stroke="none"
                d="M 0 575 C 0 602.614258 22.385763 625 50 625 L 670 625 C 697.614258 625 720 602.614258 720 575 C 720 547.385742 697.614258 525 670 525 L 50 525 C 22.385763 525 0 547.385742 0 575 Z"
            />
        </svg>
    );
}

export default MenuIcon;