import { Link } from "react-router-dom";
import Page from "../components/page/Page.js";
import localURL from '../localURL.json';
import { LargeSpacer, SmallSpacer } from "../components/Spacer/Spacer.js";
import useDetectMobile from "../hooks/useDetectMobile.js";

function Contact() {
    const { isMobile } = useDetectMobile();
    
    return (
        <Page title="Contact Me">
            <>
                <p>
                    Hi there! If you'd like to reach out to me, please feel free
                    to do so. Below is my email address and LinkedIn (plus a
                    picture of my dogs because I love them so much and didn't
                    want the page to look empty).
                </p>
                <br />

                <div
                    style={{
                        backgroundColor: "var(--backgroundColor2)",
                        borderRadius: "24px",
                        boxShadow: "var(--shadow)",
                        padding: "26px",
                    }}
                >
                    <p>
                        <b>Email</b>
                        <br />
                        <Link to="mailto: markreggiardo@gmail.com">
                            markreggiardo@gmail.com
                        </Link>
                    </p>

                    <SmallSpacer />

                    <p>
                        <b>LinkedIn</b>
                        <br />
                        <Link to="https://www.linkedin.com/in/mark-reggiardo">
                            mark-reggiardo
                        </Link>
                    </p>
                </div>

                <LargeSpacer />

                <img
                    src={localURL.api + "/assets/" + "honeyBeingSilly.jpeg"}
                    style={{
                        maxHeight: 450,
                        width: isMobile ? "100%" : "auto",
                        objectFit: "cover",
                        borderRadius: 12,
                        boxShadow: "var(--shadow)",
                    }}
                />
            </>
        </Page>
    );
}

export default Contact;