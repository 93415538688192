import "./TextEntry.css"

function TextField(props) {
    return (
        <input
            className="textEntry"
            type={props.type}
            value={props.value ?? ""}
            onChange={(event) => {
                props.onChange(event);
            }}
        />
    );
}

function TextEditor(props) {
    return (
        <textarea
            className="textEntry"
            name={props.name}
            value={props.value ?? ""}
            onChange={(event) => {
                props.onChange(event)
            }}
            style={{ minHeight: "50px", height: "200px" }}
        />
    );
}

export { TextField, TextEditor };
