
function Spacer(props) {
    return (
        <div
            style={{
                height: props?.height ?? "0px",
                width: props?.width ?? "0px"
            }}
        ></div>
    );
}

function SmallSpacer() {
    return(<Spacer height="20px"/>)
}

function LargeSpacer() {
    return <Spacer height="40px" />;
}

export { Spacer, SmallSpacer, LargeSpacer };