import { useEffect, useState } from "react";
import Page from "../components/page/Page.js";
import UserManager from "../UserManager.js";
import { Link, useNavigate } from "react-router-dom";
import { LargeSpacer, SmallSpacer } from "../components/Spacer/Spacer.js";
import Button from "../components/Button/Button.js";

function Dashboard() {
    var [user, setUser] = useState(null);
    var [errorText, setErrorText] = useState("");

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    useEffect(() => {
        UserManager.getStoredUser().then((storedUser) => {
            setUser(storedUser);
        });
    }, []);

    return (
        <Page title="Dashboard" authScope="user">
            <div style={{ display: "inline-block" }}>
                <div style={{ display: "flex" }}>
                    {user?.scopes?.includes("owner") && (
                        <Button to="/manageUsers">Manage Users</Button>
                    )}

                    {user?.scopes?.includes("admin") && (
                        <Button to="/manageProjects">Manage Projects</Button>
                    )}
                </div>

                <LargeSpacer />

                <Button
                    title="Log Out"
                    color="var(--red)"
                    action={async () => {
                        if (
                            await UserManager.logout(
                                user.email,
                                user.accessToken
                            )
                        ) {
                            navigateTo("/login");
                            window.location.reload();
                        } else {
                            setErrorText("Error logging out");
                        }
                    }}
                />

                <SmallSpacer />

                <p>{errorText}</p>
            </div>
        </Page>
    );
}

export default Dashboard;
