import './PageStyles.css';
import { Spacer, SmallSpacer } from "../../components/Spacer/Spacer.js";
import useDetectMobile from '../../hooks/useDetectMobile.js';

function PageHeader(props) {
    const { isMobile, screenClass } = useDetectMobile();
    return (
        <div className={`pageHeader ${screenClass}`}>
            <Spacer height={isMobile ? "0px" : "75px"} />
            <h1 className={`pageTitle ${screenClass}`}>{props.children}</h1>
            <SmallSpacer/>
        </div>
    );
}

export default PageHeader;